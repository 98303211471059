.data-collection-disclosure-modal {
    position: absolute;
    display: flex;
    flex-direction: column;        
    max-width: 600px;
    max-height: 600px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #ffffff;
    padding: 0px;
    border: 0px;
    
    .modal-body {
        height: 50vh;
        overflow: auto;
    }

    .modal-proceed-button {
        @extend .modal-button;

        border-radius: 0;
        margin-left: 10px;
    }

    .modal-cancel-button {
        @extend .modal-button;

        border-radius: 0;
        background-color: transparent;
        border-color: $modal-button-colour;
        color: $modal-button-colour;
    }

}