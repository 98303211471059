.assessment-container {
    @extend .row;
    margin: 0;
    padding: 2em 0;
    text-align: left;
    color: $emperor;
    &-column {
        @extend .col;
        @extend .row;
        &:nth-child(1) {
            @extend .col-10;
        }
    }
    &-title {
        font-size: $font-size-lg;
        font-weight: bold;
        line-height: 1.19;
        .dashboard-icon {
            margin-left: .7em;
            vertical-align: middle;
        }
        &-icon-block {
            display: inline-block;
        }
        .badge-proctored {
            background-color: #EFF3FF;
            color: #00469D;
            font-weight: normal;
            font-size: $font-size-xs;
            line-height: 1em;
            &-tooltip {
                width: 22.92em;
                color: #000;
                font-size: $font-size-xs;
                font-style: normal;
                font-weight: 400;
                line-height: 18px;
                white-space: pre-wrap;
                text-align: left;
                padding: 2em 2em 3em 2em;
                box-shadow: 2px 2px 4px 0px rgba(161, 161, 161, 0.3);
                background-color: #fff;
                &-arrow {
                    box-shadow: 3px 3px 4px 0px rgba(161, 161, 161, 0.1)
                }
            }
        }
    }
    &-description {
        font-size: $font-size-xs;
        line-height: 1.13;
        color: $mine-shaft;
        padding-top: .5em;
        margin-bottom: 0;
    }
    &-time {
        @include flex-row(wrap, flex-start);
        .dashboard-icon {
            margin-right: .7em;
        }
        &-added {
            color: $color-fern-frond;
            text-transform: uppercase;
            font-size: $font-size-xs; //according to the mockups it is 11px;
            font-weight: bold;
            justify-content: center;
        }
    }
    &-item {
        display: flex;
        @extend .col;
        &:nth-child(1) {
            @extend .col-6;
        }
        &:nth-child(2) {
            text-align: center;
        }
    }
    &-progress {
        @include flex-row();
        &-container {
            width: 100%;
            overflow: hidden;
        }
        .assessment-progress {
            @extend .progress;
            border-radius: 2px;
            background-color: $bon-jour-light;
            width: calc(100% - 40px);
            display: inline-block;
            vertical-align: middle;
            &-bar {
                @extend .progress-bar;
                background-color: $color-mantis;
            }
        }
        &-percentage {
            font-size: $font-size-sm;
            padding-left: 5px;
            max-width: 40px;
        }
    }
    &-icon {
        float: left;
        margin-right: 1.5em;
    }
    &-action {
        @include flex-row();
        font-weight: 600;
        font-size: $font-size-xs;
        &-start,
        &-language,
        &-incompatibe {
            width: 100%;
        }
        &-start-button {
            @include dashboard-action-button($white, $color-deep-cerulean-dark, $color-deep-cerulean-dark);
            width: 100%;
        }
        &-language {
            color: $color-warning;
            text-align: center;
            &-button {
                @include dashboard-action-button($color-deep-cerulean-dark, $white, $color-hit-gray);
                margin-top: .5em;
            }
        }
        &-incompatible {
            color: $color-warning;
            @include flex-row();
            .dashboard-icon {
                margin-right: .5em;
                flex-grow: 0;
            }
        }
        &-locked {
             color: $color-warning;
        }            
        &-complete a {
            @include unstyled-link($color-deep-cerulean-dark);
            font-size: $font-size-sm;
            letter-spacing: 0.2px;
            display: block;
        }
        &__panel {
            width: 100%;
            display: flex;
            align-items: center;
        }
    }
    &-date {
        @include flex-row();
        .dashboard-icon {
            margin-right: .5rem;
        }
        &-text {
            font-size: $font-size-md;
        }
        justify-content: left;
    }
    &:not(:last-child) {
        border-bottom: solid 1px $concrete;
    }
}

@include media-breakpoint-down(md) {
    .assessment-container {
        padding: 0 0 1rem 0;
        text-align: center;
        background-color: $white;
        box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.17);
        &,
        &:last-child {
            border-bottom: none;
            margin-bottom: 1rem;
        }
        &-icon {
            display: block;
            float: none;
            margin-right: 0;
            margin-bottom: .5rem;
        }
        &-column,
        &-item {
            &,
            &:nth-child(1) {
                flex: 0 0 100%;
                max-width: 100%;
                margin: 0;
                padding-top: .5rem;
                padding-bottom: .5rem;
                @include flex-row(wrap);
            } 
            &-icon {
                display: block;
            }     
        }
        &-item {
            &:nth-child(2) {
                border-right: 1px solid $color-alto;
            }
            &:nth-child(2),
            &:nth-child(3) {
                flex: 0 0 50%;
                max-width: 50%;
                padding-top: 0;
                padding-bottom: 0;
                font-size: $font-size-sm;
            }
        }
        &-progress-percentage {
            &::before {
                content: 'Complete: ';
                font-weight: bold;
            }
        }
        .assessment-progress {
            display: none;
        }
        &-time {
            &-added {
                position: absolute;
                top: 20px;
                right: -50%;
                font-size: $font-size-xs;
            }
        }
        &-action {
            padding-top: .75rem;
            &__panel{
                justify-content: center;
            }
        }
    }
}

@include media-breakpoint-only(md) {
    .assessment-container {
        &-title {
            height: 2.5em;
            max-height: 2.5em;
            overflow: hidden;
        }
        &-description {
            height: 4em;
            max-height: 4em;
            overflow: hidden;
        }
        &-action {
            &-start {
                &:before {
                    content: '.';
                    display: block;
                    visibility: hidden;
                    margin-top: .5em;
                }
            }
        }
    }
}
